import React from 'react';
import styles from './Pattern.module.scss';

const Pattern = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 440 400" width="100%" height="100%">
      <title>Interwoven organic shapes and circles</title>
      <ellipse className={styles.Cls1} cx="251.85" cy="204.28" rx="8.73" ry="8.51" transform="matrix(0.81, -0.59, 0.59, 0.81, -71.96, 186.8)"/>
      <path className={styles.Cls2} d="M27.08,312.16c10.83,40.74,53,60.24,69.72,68,12.88,5.95,70.13,32.42,119.21,5.67,33.15-18.06,42.82-49.64,45.15-58.61,3.33-12.85,9.42-47.94-15.17-79.38-17.42-22.26-43.91-34.65-64.26-37-7.13-.82-15.75-1.73-25,1.78-12.24,4.64-15.33,14.91-41.28,19-36,5.71-46-5.9-63.7,3.45C27.45,247.94,19.65,284.19,27.08,312.16Z"/>
      <circle className={styles.Cls3} cx="291.96" cy="174.33" r="35.62"/>
      <circle className={styles.Cls2} cx="365.64" cy="107.62" r="51.21"/>
      <path className={styles.Cls4} d="M49.49,290.06C83,356.12,157.28,371.29,186.7,377.29c22.69,4.63,123.58,25.23,190.14-43.12,45-46.16,47.48-105.38,47.54-122,.08-23.85-4.46-87.57-55.87-131.34-36.4-31-83.13-40.75-116.14-35.77-11.57,1.75-25.51,4-38.67,14.2C196.29,72.76,195.56,92,156.32,110.71c-54.39,25.92-74.94,10.2-99,34.3C24.21,178.19,26.51,244.71,49.49,290.06Zm66.15-127.9c45.09-9.8,59.94,10.22,89.19,1,40.1-12.67,43.38-62.89,77.43-62.57,35.48.34,80.15,35.65,89.19,82.17,9.25,47.55-22.1,85.77-28.42,93.11-59.29,68.85-200.41,72.74-242.2,9.8C75.22,247.06,80.76,169.74,115.64,162.16Z"/>
      <ellipse className={styles.Cls5} cx="410.49" cy="88.06" rx="15.48" ry="15.87"/>
      <path className={styles.Cls5} d="M50.12,325.33a11.19,11.19,0,0,1,7.82,2.94,11.57,11.57,0,0,1,3.41,9.35,17.51,17.51,0,0,1-4.89,10.83,17.11,17.11,0,0,1-12.23,5.66,11.19,11.19,0,0,1-7.82-2.94C31.3,346.35,32,337.3,37.89,331a17.11,17.11,0,0,1,12.23-5.66m0-2a19.17,19.17,0,0,0-13.69,6.29c-6.7,7.13-7.32,17.42-1.39,23a13.22,13.22,0,0,0,9.19,3.49,19.13,19.13,0,0,0,13.69-6.29c6.7-7.13,7.32-17.43,1.39-23a13.22,13.22,0,0,0-9.19-3.49Z"/>
      <ellipse className={styles.Cls1} cx="262.96" cy="159.82" rx="17.31" ry="17.42" transform="matrix(1, -0.03, 0.03, 1, -5.31, 9.08)"/>
      <path className={styles.Cls6} d="M352.16,217.66c13.92-33.76,10.18-64.19,8.53-76.4-10.86-80.38-80.36-116.89-85.53-119.5C203.62-14.25,103.67,10.23,86,55.88c-5.75,14.86-3.66,33.94,10,58,61.46,108.49-.72,162.3,65.37,192.62C221.23,334,320.72,293.9,352.16,217.66Z"/>
      <ellipse className={styles.Cls5} cx="24.19" cy="318.31" rx="10.16" ry="10.42"/>
    </svg>
  )
}

export default Pattern;